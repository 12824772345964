<template>
    <ODataLookup :data-object="dsHandoverTypesLookup" :whereClause="`'${IdPath}' LIKE IdPath + '%'`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="200"></OColumn>
        <OColumn field="OrgUnit" width="200"></OColumn>
        <OColumn field="Checklist" width="130"></OColumn>
        <OColumn field="Revision" width="100"></OColumn>
    </ODataLookup>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsHandoverTypesLookup = $getDataObjectById("dsComplete_HandoverTypesLookup");    
   
</script>